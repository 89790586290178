<template>
  <div id="app">
    <div class="app-header">
      <index-header />
    </div>
    <div class="app-main">
      <slot />
    </div>
    <back-top />
    <suspense-box />
    <message-box />
  </div>
</template>

<script setup>
import cookie from '@/utils/cookies'
import { useUserStore } from '../stores/user'
import { onMounted } from 'vue'
const route = useRoute()
const { getShopInfo } = useUserStore()
getShopInfo()
const cookieData = cookie.getShop()
useHead({
  link: [
    { rel: 'icon', type: 'image/x-icon', href: cookieData.shopIcon }
  ]
})
onMounted(() => {
})

</script>

<style lang="scss" scoped>
@import url('@/assets/font/iconfont.css');
#app {
  position: relative;
  background-color: #f6f8fb;
}
.app-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  background-color: #fff;
}
.app-main {
  height: 100vh;
  padding-top: 66px;
  padding-bottom:0;
  background-color: #f6f8fb;
}

.test {
  background: #f6f8fb;
}
</style>
